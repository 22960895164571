import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Msg from "./gcomp/msg";
import Spin from "./gcomp/spin";
import { APP_V, DEV_ID, DEV_PRDS, DEVS, IN_DEBUG, MIDD_URL, RC, SC, set_dev_id, set_in_modif, set_status, STATUS } from "./glb";
import { add_status, delete_device, get_devices, save_device, get_products, get_time_to_cancel } from "./reuse";

const View3 = () => {

    const [id, set_id]                                              = useState(null);
    const [new_area_input, set_new_area_input]                      = useState(null);
    const [jump_to, set_jump_to]                                    = useState(null);
    const [position, set_position]                                  = useState(0);
    const [is_start_view, set_in_start_view]                        = useState(0);
    const [is_visible, set_is_visible]                              = useState(0);
    const [to_retire, set_to_retire]                                = useState(0);
    const [automatic_cancel, set_automatic_cancel]                  = useState(0);
    const [print, set_print]                                        = useState(0);
    const [ip_address, set_ip_address]                              = useState(null);
    const [ip_address_pc, set_ip_address_pc]                        = useState(null);
    const navigate                                                  = useNavigate();
    const [dish_for_row, set_dish_for_row]                          = useState(localStorage.getItem('card_fr'));
    const [devices, set_devices]                                    = useState([]);
    const [device_code, set_device_code]                            = useState(null);
    const [display_spin, set_display_spin]                          = useState(false);
    const [color_status, set_color_status]                          = useState(null);
    const [color_text, set_color_text]                              = useState(null);
    const [token_app, set_token_app]                                = useState(null);
    const [id_token, set_id_token]                                  = useState(null);
    const [wpa_desc, set_wpa_desc]                                  = useState(null);
    const [dev_id, set_dev_id]                                      = useState(null);
    const [department_code, set_department_code]                    = useState(null);
    const [insight_data, set_insight_data]                          = useState(null);
    const [enabled_in_work_button, set_enabled_in_work_button]      = useState(localStorage.getItem('enabled_in_work_button'));
    const [enabled_websocket, set_enabled_websocket]                = useState(localStorage.getItem('enabled_websocket'));
    const [order_as_come, set_order_as_come]                        = useState(localStorage.getItem('order_as_come'));
    const [enabled_ticket_list, set_enabled_ticket_list]            = useState(localStorage.getItem('enabled_ticket_list'));
    const [sidebar_extended, set_sidebar_extended]                  = useState(localStorage.getItem('sidebar_extended'));
    const [group_command_for_minute, set_group_command_for_minute]  = useState(localStorage.getItem('group_command_for_minute'));
    const [use_two_timer, set_use_two_timer]                        = useState(localStorage.getItem('use_two_timer'));
    const [vai_filter, set_vai_filter]                              = useState(localStorage.getItem('display_vai_filter'));
    const [active_delivery, set_active_delivery]                    = useState(localStorage.getItem('delivery_take_away'));
    const [sub_bill, set_sub_bill]                                  = useState(localStorage.getItem('sub_bill'));
    const [highlight_dish, set_highlight_dish]                      = useState(localStorage.getItem('highlight_dish'));
    const [external_code, set_external_code]                        = useState(localStorage.getItem('external_code'));
    const [opened_variants, set_opened_variants]                    = useState(localStorage.getItem('opened_variants'));
    const [next_command, set_next_command]                          = useState(localStorage.getItem('next_command'));
    const [alert_screen, set_alert_screen]                          = useState(localStorage.getItem('alert_screen'));
    const [show_alert_timer, set_show_alert_timer]                  = useState(localStorage.getItem('show_alert_timer'));
    const [use_department_chat, set_use_department_chat]            = useState(localStorage.getItem('use_department_chat'));
    const [scroll_screen, set_scroll_screen]                        = useState(localStorage.getItem('scroll_screen'));
    const [alert_product_cmd, set_alert_product_cmd]                = useState(localStorage.getItem("alert_minute_product_in_command"));
    const [limit_command_for_view, set_limit_command_for_view]      = useState(localStorage.getItem('limit_command_for_view'));
    const [command_for_view, set_command_for_view]                  = useState(localStorage.getItem('command_for_view'));
    const [critical_command, set_critical_command]                  = useState(JSON.parse(localStorage.getItem('critical_command') ?? "[]"));
    const [active_critical_command, set_active_critical_command]    = useState(localStorage.getItem('active_critical_command'));

    useEffect(() => {

        const ip = localStorage.getItem('printer_ip_address');
        const ip_pc = localStorage.getItem('printer_ip_address_pc');
        if(ip){
            set_ip_address(ip);
            set_ip_address_pc(ip_pc);
        }

        get_devices().then(async (res_devices) => {
            if(res_devices.status == 200){
                const json_devices = await res_devices.json();
                if(json_devices.Result == "OK"){
                    set_devices(json_devices.Devices);
                    set_dev_id(DEV_ID);
                }
            }
        });

        fetch(`${MIDD_URL}/api/bcv/get_dylogapp_gateway?get=token_app&value=${RC}`).then(async (result) => {
            if(result.status == 200){
                try{
                    const entity = await result.json();
                    set_id_token(entity.id);
                    set_token_app(entity.token_app);
                }catch(_){}
            }
        });
        
    }, []);

    return (
        <>    
            <Spin display={display_spin}/>
            <div class="settings-page">        
                <div>
                    <small style={{fontWeight: "bold"}}>Release: { APP_V }</small>
                    <br/>
                    <small>{localStorage.getItem('uuid_app')}</small>
                    <br/>
                    <div style={{display: "flex", gap: "10px", marginTop: "20px"}}>
                        <button className="btn btn-primary" onClick={() => {
                            let csv = ``;
                            Object.keys(localStorage).forEach(l => {
                                csv += `${l}:${btoa(localStorage.getItem(l))};`;
                            });
                            
                            const element = document.createElement('a');
                            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
                            element.setAttribute('download', 'settings.dy');
                            element.style.display = "none";
                            document.body.append(element);
                            element.click();
                            document.body.removeChild(element);

                        }}>Export impostazioni</button>
                        <button className="btn btn-primary" onClick={() => {
                            const obj = document.querySelector("#import_file_settings");
                            obj.click();
                        }}>Import impostazioni</button>
                        <input type="file" style={{visibility: "hidden"}} id="import_file_settings" onChange={async (e) => {
                            
                            let files = [];
                            if((files = e.target.files).length > 0){
                                let file = files[0];
                                if(file.name.endsWith(".dy")){
                                    const txt = await file.text();
                                    txt.split(";").forEach(t => {
                                        if(t){
                                            let items = t.split(":");
                                            let key = items[0];
                                            let val = atob(items[1]);
                                            localStorage.setItem(key, val);
                                        }
                                    });
                                    alert("Import completato");
                                    window.location.href = "/";
                                }
                                
                            }

                        }} multiple={false}/>
                    </div>
                </div>
                <div style={{fontSize: "30px", color: "red", fontWeight: "bold", cursor: "pointer"}} onClick={() => navigate(-1)}>
                    <i class="fas fa-sign-out-alt" ></i>&nbsp;&nbsp;Indietro
                </div>
                <div class="setting-container status-preview">
                    <p>Stati di lavorazione</p>
                    <div class="tab-bar">
                        <div class="tabs">
                            {
                                (STATUS ?? []).sort((a, b) => a.position - b.position)
                                .map((item, index) => 
                                    <div class={`tab ${new_area_input == item.status_label ? 'active' : ''}`} draggable="false" onClick={() => {
                                        set_id(item.id);
                                        set_new_area_input(item.status_label);
                                        set_position(item.position);
                                        set_jump_to(item.change_status_to);
                                        set_in_start_view(item.is_start_view);
                                        set_is_visible(item.visible);
                                        set_print(item.print_receipt);
                                        set_color_status(item.hex_color);
                                        set_color_text(item.text_hex_color);
                                        set_wpa_desc(item.web_app_description ?? "");
                                        set_to_retire(item.to_retire ?? 0);
                                        set_automatic_cancel(item.automatic_cancel ?? 0);
                                    }}>
                                        {item.status_label}
                                    </div>
                                )
                            }
                        </div>
                    </div>     
                </div>

                <div class="setting-container add-status">
                    <p>Aggiungi uno stato di lavorazione</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-control" placeholder="Nome Stato" id="statusName" value={new_area_input} onChange={(e) => set_new_area_input(e.target.value)} disabled={new_area_input == "DA FARE"}></input>
                            <label for="statusName">Nome Stato</label>
                        </div>
                        <div class="form-floating">
                            <input class="form-control" placeholder="Posizione" id="statusPosition" value={position} onChange={(e) => set_position(e.target.value)} disabled={new_area_input == "DA FARE"}></input>
                            <label for="statusPosition">Posizione</label>
                        </div>
                        <div class="form-floating">
                            <input class="form-control" placeholder="Etichetta in webapp" id="statusPosition" value={wpa_desc} onChange={(e) => set_wpa_desc(e.target.value)}></input>
                            <label for="statusPosition">Etichetta in webapp</label>
                        </div>
                        <div class="form-floating">
                            <input class="form-control" placeholder="Colore stato" id="colorStatus" type="color" value={color_status} onChange={(e) => set_color_status(e.target.value)} disabled={new_area_input == "DA FARE"}></input>
                            <label for="statusPosition">Colore di sfondo</label>
                        </div>
                        <div class="form-floating">
                            <input class="form-control" placeholder="Colore testo" id="colorText" type="color" value={color_text} onChange={(e) => set_color_text(e.target.value)} disabled={new_area_input == "DA FARE"}></input>
                            <label for="statusPosition">Colore testo</label>
                        </div>
                        <div class="form-floating">
                            <select class="form-select" id="change_status_to" onChange={(e) => {
                                set_jump_to(e.target.value);
                            }}>
                                <option selected={jump_to == null} value={null}>-- NIENTE --</option>
                                {
                                    (STATUS ?? []).map((item) => 
                                        <option selected={item.status_enum == jump_to} value={item.status_enum}>{item.status_label}</option>
                                    )   
                                }
                            </select>
                            <label for="change_status_to">Sezione di spostamento al doppio TAP</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={is_visible == 1} disabled={new_area_input == "DA FARE"} onChange={() => {
                                set_is_visible(is_visible == 1 ? 0 : 1);
                            }}/>
                            <label class="form-check-label" for="defaultStatus">Stato visibile</label>
                        </div>                        
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={print == 1} onChange={() => {
                                set_print(print == 1 ? 0 : 1);
                            }}/>
                            <label class="form-check-label" for="defaultStatus">Stampa al passaggio di stato</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={to_retire == 1} onChange={() => {
                                set_to_retire(to_retire == 1 ? 0 : 1);
                            }}/>
                            <label class="form-check-label" for="defaultStatus">Stato di ritiro</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={automatic_cancel == 1} onChange={() => {
                                set_automatic_cancel(automatic_cancel == 1 ? 0 : 1);
                            }}/>
                            <label class="form-check-label" for="defaultStatus">Cancellazione automatica ticket</label>
                        </div>
                        <button type="button" class="btn btn-primary btn-lg" onClick={async () => {
                    
                            if(!new_area_input) return;

                            set_display_spin(true);
                            await new Promise((resolve) => setTimeout(() => resolve(), 1000));

                            const res_new_area = await add_status(
                                id, 
                                new_area_input, 
                                position ?? 0, 
                                jump_to ?? new_area_input.trim().split(" ").join("_").toLowerCase(), 
                                is_start_view, 
                                is_visible, 
                                print, 
                                color_status, 
                                color_text,
                                wpa_desc,
                                to_retire,
                                automatic_cancel
                            );

                            if(res_new_area.status == 200){
                                const json_new_area = await res_new_area.json();
                                if(json_new_area.Result == "ERR"){
                                    if(IN_DEBUG){
                                        console.log(json_new_area.Description);
                                    }
                                }else{
                                    if(!id){
                                        const tmp_status = [...STATUS];
                                        tmp_status.push(json_new_area.Json);
                                        set_status(tmp_status);
                                    }else{
                                        const index = STATUS.findIndex(item => item.id == id);
                                        if(index > -1){
                                            STATUS[index] = JSON.parse(JSON.stringify(json_new_area.Json));
                                        }
                                    }
                                }
                            }

                            set_display_spin(false);

                        }}>Salva</button>
                        <button type="button" class="btn btn-primary btn-lg" onClick={async () => { 
                            set_id(null);
                            set_new_area_input('');
                            set_is_visible(0);
                            set_print(0);
                            set_jump_to(null);
                            set_position('');
                            set_wpa_desc('');
                        }}>Nuovo</button>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Barra piatti estesa</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={sidebar_extended == '1'} onChange={() => {
                                localStorage.setItem('sidebar_extended', sidebar_extended == '1' ? '0' : '1');
                                set_sidebar_extended(sidebar_extended == '1' ? '0' : '1');
                            }}/>&nbsp;&nbsp;Barra piatti estesa
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Sottoconti</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={sub_bill == '1'} onChange={() => {
                                localStorage.setItem('sub_bill', sub_bill == '1' ? '0' : '1');
                                set_sub_bill(sub_bill == '1' ? '0' : '1');
                            }}/>&nbsp;&nbsp;Abilita gestione sottoconti
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Mostra codice esterno</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={external_code == '1'} onChange={() => {
                                localStorage.setItem('external_code', external_code == '1' ? '0' : '1');
                                set_external_code(external_code == '1' ? '0' : '1');
                            }}/>&nbsp;&nbsp;Mostra codice esterno
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Colori</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-control" placeholder="Colore" type="color" id="color_screen"  defaultValue={localStorage.getItem('override_color')}></input>
                            <label for="color_screen">Colore</label>
                        </div>
                        <button className="btn btn-primary" onClick={() => {
                            localStorage.setItem('override_color', document.querySelector("#color_screen").value);
                            window.location.href = "/";
                        }}>Imposta colore</button>
                    </div>
                    <hr/>
                    <div class="status-setting">
                        <button className="btn btn-primary" onClick={() => {
                            localStorage.setItem('override_color', '#008833');
                            window.location.href = "/";
                        }}>Reset colori</button>
                    </div>
                </div>                

                <div class="setting-container add-status">
                    <p>Limita numero di comande a video</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={limit_command_for_view == '1'} onChange={() => {
                                localStorage.setItem('limit_command_for_view', limit_command_for_view == '1' ? '0' : '1');
                                set_limit_command_for_view(limit_command_for_view == '1' ? '0' : '1');
                            }}/>&nbsp;&nbsp;Limita le comande a schermo
                        </div>
                        <div class="form-floating">
                            <input class="form-control" placeholder="Indicare numero comande" id="command_for_view" onChange={(e) => {
                                if(parseInt(e.target.value)){
                                    localStorage.setItem('command_for_view', parseInt(e.target.value).toString())
                                }
                            }} defaultValue={localStorage.getItem('command_for_view')}></input>
                            <label for="command_for_view">Indicare numero comande</label>
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Chat</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={use_department_chat == '1'} onChange={() => {
                                localStorage.setItem('use_department_chat', use_department_chat == '1' ? '0' : '1');
                                set_use_department_chat(use_department_chat == '1' ? '0' : '1');
                            }}/>&nbsp;&nbsp;Abilita messagistica tra reparti
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Alerts</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={show_alert_timer == '1'} onChange={() => {
                                localStorage.setItem('show_alert_timer', show_alert_timer == '1' ? '0' : '1');
                                set_show_alert_timer(show_alert_timer == '1' ? '0' : '1');
                            }}/>&nbsp;&nbsp;Mostra timer prossimo alert
                        </div>
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={alert_screen == '1'} onChange={() => {
                                localStorage.setItem('alert_screen', alert_screen == '1' ? '0' : '1');
                                set_alert_screen(alert_screen == '1' ? '0' : '1');
                            }}/>&nbsp;&nbsp;Informa sull'utente se ci sono delle comande non visibili a schermo
                        </div>
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={scroll_screen == '1'} onChange={() => {
                                localStorage.setItem('scroll_screen', scroll_screen == '1' ? '0' : '1');
                                set_scroll_screen(scroll_screen == '1' ? '0' : '1');
                            }}/>&nbsp;&nbsp;Scroll automatico se ci sono comande non visibili a schermo
                        </div>
                        <hr/>
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={alert_product_cmd == '1'} onChange={() => {
                                localStorage.setItem('alert_minute_product_in_command', alert_product_cmd == '1' ? '0' : '1');
                                set_alert_product_cmd(alert_product_cmd == '1' ? '0' : '1');
                            }}/>&nbsp;&nbsp;Alert per prodotti in comanda non gestiti dopo un certo numero di minuti
                        </div>
                        <div class="form-floating">
                            <input class="form-control" placeholder="Minuti" id="alert_minute_product_in_command_time" onChange={(e) => {
                                if(parseInt(e.target.value)){
                                    localStorage.setItem('alert_minute_product_in_command_time', parseInt(e.target.value).toString())
                                }
                            }} defaultValue={localStorage.getItem('alert_minute_product_in_command_time')}></input>
                            <label for="alert_minute_product_in_command_time">Indicare minuti</label>
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Colori criticità comanda</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={active_critical_command == '1'} onChange={() => {
                                localStorage.setItem('active_critical_command', active_critical_command == '1' ? '0' : '1');
                                set_active_critical_command(active_critical_command == '1' ? '0' : '1');
                            }}/>&nbsp;&nbsp;Attiva gestione colori
                        </div>
                        <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                            <div class="form-floating" style={{width :"100%"}}>
                                <input className="form-control" placeholder="Minuti di partenza Es: 10" type="number" id="critical_start_minute"></input>
                                <label>Minuti di partenza Es: 10</label>
                            </div>  
                            <div class="form-floating" style={{width :"100%"}}>
                                <input className="form-control" placeholder="Minuti di termine Es: 20" type="number" id="critical_end_minute"></input>
                                <label>Minuti di termine Es: 20</label>
                            </div>
                            <div class="form-floating" style={{width :"100%"}}>
                                <input className="form-control" type="color" id="critical_color"></input>
                                <label>Colore</label>
                            </div>
                            <button className="btn btn-primary" onClick={() => {

                                const f1        = document.querySelector("#critical_start_minute");
                                const f2        = document.querySelector("#critical_end_minute");
                                const f3        = document.querySelector("#critical_color");
                                let start       = f1.value;
                                let end         = f2.value;
                                const color     = f3.value;

                                if(start && end && color && !isNaN(start) && !isNaN(end)){
                                    start = parseInt(start);
                                    end = parseInt(end);
                                    let cmds = (critical_command ?? []);
                                    cmds.push({start_from: start, end_to: end, color: color});
                                    localStorage.setItem('critical_command', JSON.stringify(cmds));
                                    set_critical_command( JSON.parse(JSON.stringify( cmds ) ) );
                                }

                                f1.value = "";
                                f2.value = "";
                                f3.value = "";

                            }}>Aggiungi</button>
                        </div>
                        {
                            (critical_command ?? []).map((item, index) => 
                                <div key={`critical_command_${index}`} style={{display: "flex", alignItems: "center", gap: "10px"}}>
                                    <div style={{width: "30px", height: "30px", borderRadius: "10000px", background: item.color}}></div>
                                    <div>Da minuti {item.start_from} a minuti {item.end_to}</div>
                                    <button className="btn btn-danger" onClick={() => {
                                        critical_command.splice(index, 1);
                                        localStorage.setItem('critical_command', JSON.stringify(critical_command));
                                        set_critical_command(JSON.parse(JSON.stringify(critical_command)));
                                    }}>Rimuovi</button>
                                </div>
                            )
                        }
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Avanzamento comanda</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={next_command == '1'} onChange={() => {
                                localStorage.setItem('next_command', next_command == '1' ? '0' : '1');
                                set_next_command(next_command == '1' ? '0' : '1');
                                window.location.href = "/";
                            }}/>&nbsp;&nbsp;Avanza la comanda in modo completo
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Highlight piatti</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={highlight_dish == '1'} onChange={() => {
                                localStorage.setItem('highlight_dish', highlight_dish == '1' ? '0' : '1');
                                set_highlight_dish(highlight_dish == '1' ? '0' : '1');
                            }}/>&nbsp;&nbsp;Abilita highlight piatti da lavorare
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Varianti visibili</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={opened_variants == '1'} onChange={() => {
                                localStorage.setItem('opened_variants', opened_variants == '1' ? '0' : '1');
                                set_opened_variants(opened_variants == '1' ? '0' : '1');
                            }}/>&nbsp;&nbsp;Abilitando questa spunta le varianti risulteranno fin da subito visibili
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Gestione a 2 timer</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={use_two_timer == '1'} onChange={() => {
                                localStorage.setItem('use_two_timer', use_two_timer == '1' ? '0' : '1');
                                set_use_two_timer(use_two_timer == '1' ? '0' : '1');
                            }}/>&nbsp;&nbsp;Organizza le comande in funzione dell'orario di arrivo e di lavorazione
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Abilita filtro "VAI"</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={vai_filter == '1'} onChange={() => {
                                localStorage.setItem('display_vai_filter', vai_filter == '1' ? '0' : '1');
                                set_vai_filter(vai_filter == '1' ? '0' : '1');
                            }}/>&nbsp;&nbsp;Mostra filtro VAI CON
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Bordo rosso comanda</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <p>Indicare numero di minuti massimo di attesa di una comanda</p>
                            <div class="form-floating">
                                <input class="form-control" placeholder="Minuti" id="max_minute_for_command" onChange={(e) => {
                                    if(parseInt(e.target.value)){
                                        localStorage.setItem('max_minutes_for_command', parseInt(e.target.value).toString())
                                    }
                                }} defaultValue={localStorage.getItem('max_minutes_for_command')}></input>
                                <label for="max_minutes_for_command">Indicare numero di minuti massimo di attesa di una comanda</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Font linea comanda</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <p>Font linea comanda</p>
                            <div class="form-floating">
                                <input class="form-control" placeholder="Minuti" id="line_command_font" onChange={(e) => {
                                    if(parseInt(e.target.value)){
                                        localStorage.setItem('line_font_size', parseInt(e.target.value).toString())
                                    }
                                }} defaultValue={localStorage.getItem('line_font_size')}></input>
                                <label for="line_command_font">Font linea comanda</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Raggruppa comande per minutaggio (2 min)</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={group_command_for_minute == '1'} onChange={() => {
                                localStorage.setItem('group_command_for_minute', group_command_for_minute == '1' ? '0' : '1');
                                set_group_command_for_minute(group_command_for_minute == '1' ? '0' : '1');
                            }}/>&nbsp;&nbsp;Raggruppa comande per minutaggio. <b>Se disattivo, verrà effettuato un raggruppamento attraverso codice sessione</b>
                            <br/><b style={{color: "red"}}>DA DISATTIVARE SOLO SE SI POSSIEDE IN RESTGATEWAY LA GESTIONE DEL NUMERO DI SESSIONE</b>
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Delivery & Take Away</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={active_delivery == '1'} onChange={() => {
                                localStorage.setItem('delivery_take_away', active_delivery == "1" ? "0" : "1");
                                set_active_delivery(active_delivery == "1" ? "0" : "1");
                            }}/>&nbsp;&nbsp;Attiva gestione delivery & take away
                            <br/><b style={{color: "red"}}>SE ATTIVO, ASSICURARSI CHE SIA COMPATIBILE CON LA VERSIONE DEL REST GATEWAY</b>
                        </div>
                        <div class="form-floating">
                            <input class="form-control" placeholder="Ore di anticipo" id="hour_landing" onChange={(e) => {
                                if(parseInt(e.target.value)){
                                    localStorage.setItem('delivery_hour_landing', parseInt(e.target.value).toString())
                                }
                            }} defaultValue={localStorage.getItem('delivery_hour_landing')}></input>
                            <label for="hour_landing">Indicare quante ore prima l'ordine deve atterrare nella video comanda</label>
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Pulsante in lavorazione</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={enabled_in_work_button == '1'} onChange={() => {
                                localStorage.setItem('enabled_in_work_button', enabled_in_work_button == '1' ? '0' : '1');
                                set_enabled_in_work_button(enabled_in_work_button == '1' ? '0' : '1');
                            }}/>&nbsp;&nbsp;Abilita in lavorazione
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>WebSocket</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={enabled_websocket == '1'} onChange={() => {
                                localStorage.setItem('enabled_websocket', enabled_websocket == '1' ? '0' : '1');
                                window.location.href = "/";
                            }}/>&nbsp;&nbsp;Abilita websocket
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Ordina le comande per ID</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={order_as_come == '1'} onChange={() => {
                                localStorage.setItem('order_as_come', order_as_come == '1' ? '0' : '1');
                                window.location.href = "/";
                            }}/>&nbsp;&nbsp;Ordina le comande per ID
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Gestione Ticket List</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-check-input" type="checkbox" value="" id="defaultStatus" checked={enabled_ticket_list == '1'} onChange={() => {
                                localStorage.setItem('enabled_ticket_list', enabled_ticket_list == '1' ? '0' : '1');
                                window.location.href = "/";
                            }}/>&nbsp;&nbsp;Abilita
                        </div>
                        <div>
                            <button className="btn btn-danger" onClick={async () => {
                                const res = window.confirm("Sei sicuro di voler procedere? L'operazione è irreversibile!");
                                if(res){
                                    try{
                                        const res = 
                                            await fetch(
                                                `${MIDD_URL}/api/v1/bcv/delete_ticket_list?restaurant_code=${RC}&subscriber_code=${SC}`,
                                                { 
                                                    method: "GET"
                                                }
                                            );
                                        if(res.status == 200){
                                            const json = await res.json();
                                            if(json.Result == "OK"){
                                                window.location.href = "/";
                                                return;
                                            }
                                        }
                                    }catch(_){}
                                    alert("Errore durante la cancellazione..");
                                }
                            }}>Cancella tickets</button>
                        </div>
                    </div>
                </div>


                <div class="setting-container add-status">
                    <p>Subscriber sync prodotti</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <select className="form-select" id="subscriber-sync-product" defaultValue={localStorage.getItem('subscriber_sync_product')} onChange={(e) => {
                                const value = e.target.value;
                                localStorage.setItem('subscriber_sync_product', value);
                            }}>
                                <option value="DylogApp">DylogAPP</option>
                                <option value="DylogVIDEOCOMANDE">DylogVIDEOCOMANDE</option>
                            </select>
                            <label for="subscriber-sync-product">Subscriber sync prodotti</label>
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Ordinamento tavoli</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <select class="form-select" id="order_table_select" defaultValue={localStorage.getItem('order_table_by')} onChange={(e) => {
                                const order_by = e.target.value;
                                localStorage.setItem('order_table_by', order_by);
                            }}>
                                <option value={"abc"}>Alfabetico</option>
                                <option value={"time"}>Tempo</option>
                            </select>
                            <label for="order_table_select">Ordina tavoli</label>
                        </div>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Cancellazione ticket list automatica</p>
                        <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-control" placeholder="Minuti" id="time_to_cancel" defaultValue={localStorage.getItem('time_to_cancel')}></input>
                            <label for="time_to_cancel">Minuti di cancellazione</label>
                        </div>
                        <button type="button" class="btn btn-primary btn-lg" onClick={async () => {
                            const val = document.querySelector("#time_to_cancel").value;
                            let n = -1;
                            if(!isNaN(val)){
                                n = parseInt(val);
                                localStorage.setItem('time_to_cancel', n.toString());
                                let id = localStorage.getItem('id_time_to_cancel');
                                await fetch(
                                    `${MIDD_URL}/api/bcv/set_time_to_cancel?restaurant_code=${RC}&time_to_clear=${n}${id && id != "-1" ? `&id=${id}` : ''}`
                                );
                                await get_time_to_cancel();
                                alert("Salvataggio effettuato")
                            }else{
                                alert("Numero minuti non valido!!")
                            }
                        }}>Salva</button>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Stampante non fiscale</p>
                    <div class="status-setting">
                    <div class="form-floating">
                            <input class="form-control" placeholder="Nome Stato" id="statusName" value={ip_address_pc} onChange={(e) => set_ip_address_pc(e.target.value)}></input>
                            <label for="statusName">Indirizzo IP PC</label>
                        </div>
                        <div class="form-floating">
                            <input class="form-control" placeholder="Nome Stato" id="statusName" value={ip_address} onChange={(e) => set_ip_address(e.target.value)}></input>
                            <label for="statusName">Indirizzo IP Stampante</label>
                        </div>
                        <button type="button" class="btn btn-primary btn-lg" onClick={async () => {
                            localStorage.setItem('printer_ip_address', ip_address);
                            localStorage.setItem('printer_ip_address_pc', ip_address_pc);
                        }}>Salva</button>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Numero piatti per riga</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-control" placeholder="Nome Numero piatti per riga" id="statusName" value={dish_for_row} onChange={(e) => set_dish_for_row(e.target.value)}></input>
                            <label for="statusName">Numero piatti per riga</label>
                        </div>
                        <button type="button" class="btn btn-primary btn-lg" onClick={async () => {

                            //let override = "";
                            //for(let d = 0; d < parseInt(dish_for_row); d++){
                            //    override += "1fr ";
                            //} 

                            //document.documentElement.style.setProperty('--grid-layout', override);
                            localStorage.setItem('card_fr', dish_for_row);

                        }}>Salva</button>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Gestione coperti</p>
                    <div class="status-setting">
                        <button className="btn btn-primary" style={{width: "250px"}} onClick={async () => {

                            set_display_spin(true);

                            const products = await get_products();
                            set_display_spin(false);

                            if(products && products.length){
                                
                                const product_covers = products.filter(p => p.is_cover);
                                if(product_covers && product_covers.length > 0){
                                    
                                    try{
                                        const ids = [];
                                        product_covers.forEach((p) => ids.push(parseInt(p.id)));
                                        const res = await fetch(
                                            `${MIDD_URL}/api/v1/bcv/set_covers?restaurant_code=${RC}&subscriber_code=${SC}`,
                                            {
                                                method: "POST",
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify(
                                                    {
                                                        covers: ids
                                                    }
                                                )
                                            }
                                        );

                                        if(res && res.status == 200){
                                            const json = await res.json();
                                            if(json.Result == "OK"){
                                                console.log("Setted covers!");
                                            }
                                        }
                                    }catch(_){}

                                }
                                set_display_spin(false);
                                
                            }

                        }}>Effettua download coperti</button>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Reparti</p>
                    <div class="status-setting">
                        {
                            devices && devices.length > 0 ? 
                                <>
                                    <div class="form-floating">
                                        <select class="form-select" onChange={(e) => {
                                            //localStorage.setItem('device', e.target.value);
                                            //set_dev_id(parseInt(e.target.value));
                                            //window.location.href = "/";
                                            set_dev_id(parseInt(e.target.value));
                                        }}>
                                            {
                                                devices.map((device) => 
                                                    <option value={device.id} selected={device.id.toString() == localStorage.getItem('device')}>{device.code}</option>
                                                )
                                            }
                                        </select>
                                        <button className="btn btn-primary" style={{marginTop: "10px"}} onClick={() => navigate('/products')}>Configurazione prodotti</button>
                                        <div class="form-floating" style={{marginTop: "20px"}}>
                                            <input class="form-control" onChange={(e) => { set_department_code(e.target.value) }}></input>
                                            <label>Nuovo codice reparto</label>
                                        </div>
                                        <button className="btn btn-primary" style={{marginTop: "10px"}} onClick={async () => {
                                            if(dev_id && department_code){
                                                set_display_spin(true);
                                                await save_device(department_code, dev_id);
                                                window.location.href = "/";
                                            }
                                        }}>Modifica reparto</button>
                                        <button className="btn btn-danger" style={{marginTop: "10px", marginLeft: "10px"}} onClick={async () => {
                                            
                                            if(dev_id){
                                                if(await delete_device(dev_id)){
                                                    window.location.href = "/";
                                                }
                                            }

                                        }}>Cancella reparto</button>
                                        <label>Reparti presenti</label>
                                    </div>
                                </>
                            :
                                <></>
                        }
                        <div style={{width: "100%", border: "1px solid rgb(220, 220, 220)"}}></div>
                        <div class="form-floating">
                            <input class="form-control" onChange={(e) => { set_device_code(e.target.value) }}></input>
                            <label>Codice reparto</label>
                        </div>
                        <button type="button" class="btn btn-primary btn-lg" onClick={async () => {
                            
                            if(!device_code) return;

                            set_display_spin(true);
                            await new Promise((resolve) => setTimeout(() => resolve(), 1000));

                            const is_present = devices.filter(item => item.code == device_code).length > 0;
                            if(!is_present){
                                const res_device = await save_device(device_code);
                                if(res_device.status == 200){
                                    const json_device = await res_device.json();
                                    if(json_device.Result == "OK"){
                                        const tmp_devices = [...(devices ?? [])];
                                        tmp_devices.push(json_device.Json);
                                        set_devices(tmp_devices);
                                    }
                                }
                                //window.location.href = "/";
                            }
                            set_display_spin(false);

                        }}>Crea reparto</button>
                        <div style={{width: "100%", border: "1px solid rgb(220, 220, 220)"}}></div>
                        <p>Gestione riporti tra reparti di produzione</p>
                        <button className="btn btn-primary btn-lg" onClick={() => navigate("/carry")}>Imposta riporti</button>
                        <div style={{width: "100%", border: "1px solid rgb(220, 220, 220)"}}></div>
                        <p>Analisi tempi reparti di produzione</p>
                        <button className="btn btn-primary btn-lg" onClick={async () => {

                            let data = [];

                            try{
                                const response = await fetch(`http://217.160.159.28/api/bcv/get_timing_department_production?restaurant_code=${RC}`);
                                if(response.status == 200){

                                    const json = await response.json();
                                    if(json.Result == "OK"){
                                        (json.Data ?? []).forEach(d => {
                                            const index = DEVS.findIndex(i => i.id == d.device_id);
                                            if(index > -1){
                                                data.push({
                                                    device: DEVS[index].code,
                                                    movement_count: d.movement_count,
                                                    average: d.average,
                                                    real_average_time: (d.average / d.movement_count),
                                                    from_status: d.from_status,
                                                    to_status: d.to_status
                                                });
                                            }
                                        });
                                    }

                                }
                            }catch(e){}

                            if(data.length == 0){
                                alert("Non ci sono dati statistici da mostrare al momento");
                                return;
                            }

                            set_insight_data(data);

                        }}>Effettua analisi</button>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Webapp</p>
                    <div class="status-setting">
                        <div class="form-floating">
                            <input class="form-control" placeholder="Token app" id="statusName" value={token_app} onChange={(e) => set_token_app(e.target.value)}></input>
                            <label for="statusName">Token app</label>
                        </div>
                        <button type="button" class="btn btn-primary btn-lg" onClick={async () => {

                            set_display_spin(true);
                            fetch(
                                `${MIDD_URL}/api/bcv/join_dylogapp_gaetway`,
                                {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        id: id_token,
                                        token_app: token_app,
                                        restaurant_code: RC
                                    })
                                }
                            ).then(async (res) => {
                                if(res.status == 200){
                                    const entity = await res.json();
                                    if(entity.Result == "OK"){
                                        set_id_token(entity.Id);
                                    }
                                }
                                set_display_spin(false);
                            });

                        }}>Salva</button>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Genera report giornaliero</p>
                    <div class="status-setting">
                        <button type="button" class="btn btn-primary btn-lg" onClick={async () => {
                            const today = new Date();
                            const dt = today.getFullYear() + "-" + (today.getMonth() + 1).toString() + "-" + today.getDate().toString();
                            set_display_spin(true);
                            await fetch(`${MIDD_URL}/api/v1/bcv/report?type=launch&restaurant_code=${RC}&date=${dt}`);
                            set_display_spin(false);
                            window.open(`${MIDD_URL}/reports/${RC}/${dt.split("-").join("_")}/launch.pdf`, '_blank');
                        }}>Genera per orario di pranzo</button>
                        <button type="button" class="btn btn-primary btn-lg" onClick={async () => {
                            const today = new Date();
                            const dt = today.getFullYear() + "-" + (today.getMonth() + 1).toString() + "-" + today.getDate().toString();
                            set_display_spin(true);
                            await fetch(`${MIDD_URL}/api/v1/bcv/report?type=dinner&restaurant_code=${RC}&date=${dt}`);
                            set_display_spin(false);
                            window.open(`${MIDD_URL}/reports/${RC}/${dt.split("-").join("_")}/dinner.pdf`, '_blank');
                        }}>Genera per orario di cena</button>
                    </div>
                </div>

                <div class="setting-container add-status">
                    <p>Logout</p>
                    <div class="status-setting">
                        <button type="button" class="btn btn-danger btn-lg" onClick={async () => {
                            localStorage.removeItem('bc_rc');
                            localStorage.removeItem('bc_sc');
                            localStorage.removeItem('bc_pw');
                            window.location.href = "/";
                        }}>Esci</button>
                    </div>
                </div>

            </div>


            {
                // Maschera statistica tempistiche reparto di produzione
            }
            {
                insight_data &&
                    <div style={{background: "#00000078", width: window.innerWidth.toString() + "px", height: window.innerHeight.toString() + "px", position: "fixed", top: "0", left: "0", }}>
                        <div style={{width: (window.innerWidth * 0.70).toString() + "px", height: (window.innerHeight * 0.70).toString() + "px", background:"white", padding: "20px", overflowY:"auto"}}>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <h2 style={{color: "var(--green)"}}>Statistiche reparti di produzione</h2>
                                <button className="btn btn-danger" onClick={() => set_insight_data(null)}>CHIUDI</button>
                            </div>
                            <div>
                                {
                                    (insight_data ?? []).map((item, index) => 
                                        <div key={`ins_${index}`} style={{border: "1px solid #b1b1b1", marginTop: "20px", padding: "10px"}}>
                                            <h3>Reparto {item.device}</h3>
                                            <div>NUMERO DI SPOSTAMENTO EFFETTUATI DA <b style={{fontSize: "20px"}}>{item.from_status}</b> A <b style={{fontSize: "20px"}}>{item.to_status}</b></div>
                                            <p style={{fontWeight: "bold", color: "red", fontSize: "20px"}}>{item.movement_count}</p>
                                            <div>TEMPO MASSIVO</div>
                                            <p style={{fontWeight: "bold", color: "red", fontSize: "20px"}}>{item.average} minuti</p>
                                            <div>TEMPO MEDIO</div>
                                            <p style={{fontWeight: "bold", color: "red", fontSize: "20px"}}>{item.real_average_time.toFixed(2)} minuti</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
            }


        </>
    );
};

export {
    View3
}